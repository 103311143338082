import React from "react";
import glabolLogo from "../../assets/images/glabol-logo.png";
import "../footer/Footer.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import appstore from "../../assets/images/AppStore.png";
import playstore from "../../assets/images/PlayStore.png";
function Footer() {
  const handleButtonClick = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.swapac.glabolexpedition&pli=1";
  };
  const handleButtonClick2 = () => {
    window.location.href = "https://apps.apple.com/in/app/glabol/id6737472845";
  };

  return (
    <>
      <div
        className="text-align d-flex justify-content-between"
        style={{ backgroundColor: "#DC1B5E", marginBottom: "0" }}
      >
        <div className="container">
          <div className="d-flex align-items-center gap-2 for_smalldf">
            <div className="text col-lg-10 col-sm-6 pe-4">
              "Discover Your Next Adventure: Unleash the World Awaits"
            </div>
            <div className="col-lg-2 clo-sm-6">
              <div className="d-flex ms-auto pt-0 pb-2">
                <p className="for_icons mx-2 px-2 ">
                  <a
                    href="https://www.facebook.com/glabolindia/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="iconcolor"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </p>
                <p className="for_icons mx-2 px-2">
                  <a
                    href="https://www.instagram.com/glabolindia/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="iconcolor"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </p>
                <p className="for_icons mx-2 px-2">
                  <a
                    href="https://mobile.twitter.com/glabolindia"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="iconcolor"
                  >
                    <i className="fa-brands fa-x-twitter"></i>
                  </a>
                </p>
                <p className="mx-2 px-2 for_icons">
                  <a
                    href="https://www.linkedin.com/company/glabol-india/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="iconcolor"
                  >
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-3">
          <div className="col-sm-12 col-md-6 col-lg-6 col-12 pe-5">
            <div className="mt-3 custom-mobile-view">
              <div className="text-start">
                <img src={glabolLogo} className="img-fluid" alt="Glabol Logo" />
              </div>
              <h4
                className="text-start mt-3 font_heading"
                style={{ color: "#DC1B5E" }}
              >
                ABOUT US
              </h4>
              <p className="text-start fs-6 for_about2 text-align-justify about_list">
                Glabol India offers comprehensive travel packages for India and
                abroad, including road trips, trekking adventures, corporate
                outings, and custom tours. We specialize in group travel
                experiences, providing top-notch accommodations, guided
                sightseeing, and budget-friendly pricing. Tell us your travel
                dates and prepare for a unique and hassle-free journey.
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-6 mt-3 px-5 quicklink custom-mobile-view">
            <h3
              className="fs-5 text-start font_heading mb-3"
              style={{ color: "#DC1B5E" }}
            >
              QUICK LINKS
            </h3>
            <div className=" flex-column">
              <ul className="list-unstyled about_list">
                <li>
                  {" "}
                  <Link className="fs-6 text-start for_link" to={"./"}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="fs-6 text-start for_link"
                    to={"./Disclaimer"}
                  >
                    Disclaimer
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    className="fs-6 text-start for_link"
                    to={"./PrivacyPolicy"}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    className="fs-6 text-start for_link"
                    to={"./CancellationPolicy"}
                  >
                    Cancellation Policy
                  </Link>
                </li>
                <li>
                  <Link
                    className="fs-6 text-start for_link"
                    to={"./TermsConditions"}
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link className="fs-6 text-start for_link" to={"./aboutus"}>
                    About us
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link className="fs-6 text-start for_link" to={"./blog"}>
                    Blog
                  </Link>
                </li>
                <li>
                  <Link className="fs-6 text-start for_link" to={"/contactus"}>
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link className="fs-6 text-start for_link" to={"./Career"}>
                    Career
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-2 col-6 mt-3 px-2 quicklink custom-mobile-view">
            <h3
              className="fs-5 text-start font_heading mb-3"
              style={{ color: "#DC1B5E" }}
            >
              TRIPS
            </h3>
            <ul className="list-unstyled about_list forsmallfooterlist">
              <li>
                <Link
                  className="fs-6 text-start for_link"
                  to={"./backpackingtrips"}
                >
                  BACKPACKING TRIPS
                </Link>
              </li>
              <li>
                <Link
                  className="fs-6 text-start mt-1 for_link"
                  to={"./international-departure"}
                  style={{ whiteSpace: "nowrap" }}
                >
                  INTERNATIONAL DEPARTURES
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  className="fs-6 text-start mt-1 for_link"
                  to={"./weekendtrips"}
                >
                  WEEKEND TRIPS
                </Link>
              </li>
            </ul>
            <div>
              <button onClick={handleButtonClick} className="button">
                <img src={playstore} alt="Google Play" className="imagelogoo" />
              </button>
            </div>

            <div style={{ marginTop: "10px" }}>
              <button onClick={handleButtonClick2} className="button2">
                <img src={appstore} alt="App Store" className="imagelogoo" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="text-light mt-4 pb-1 text-center custom-mobile-view"
        style={{ backgroundColor: "#DC1B5E" }}
      >
        <h2 className="pt-4 font_heading2 for_smallheading ">
          GLABOL EXPEDITION PVT LTD
        </h2>
        <p className="fs-5 mt-4">
          {" "}
          <i className="fas fa-house mb-4 "></i>&nbsp;
          <span className="font-address">Address</span>
        </p>
        <p className="for_luckybakery">
          1881D, Above UCO Bank, Near Lucky Bakery, Sudama Nagar.
        </p>
        <div className="d-flex justify-content-center forsmallforspace">
          <p className="for_smalldetails">
            <i className="fas fa-envelope forsmallhide"></i>{" "}
            <span>info@glabol.com</span>
          </p>
          <p className="px-2 for_smalldetails">
            <i className="fas fa-phone"></i> +91 89622 45021
          </p>
          <p className="px-2 for_smalldetails">
            <i className="fas fa-globe-americas"></i> www.glabol.com
          </p>
        </div>
        <p className=" mt-4 mb-2 forrights">
          Copyright © 2019 Glabol India. All rights reserved.
        </p>
      </div>
    </>
  );
}

export default Footer;
