import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BlogPage.css";
import bgpage from "../../../assets/images/blogp1.png";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
import { Loader } from "../../loader/Loader";
import Swal from "sweetalert2";
import { RedirectUrlAfterLogin } from "../../repeatComponent/RedirectUrlAfterLogin";
import { useNavigate } from "react-router-dom";

interface MyDataItemIF {
  _id: string;
  title: string;
  blog_image: { src: string }[];
}

const BlogPage: React.FC = () => {
  const [myData, setMyData] = useState<MyDataItemIF[]>([]);
  const [isError, setIsError] = useState<string | null>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();

  const pageApidata = async () => {
    setLoad(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-all-blog`
      );
      setMyData(res.data.data);
    } catch (error) {
      setIsError("Error fetching data. Please try again later.");
      setLoad(false);
    }
    setLoad(false);
  };

  const searchBlogByTitle = async (title: string) => {
    try {
      setLoad(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/getBlogByname`,
        { title }
      );
      if (
        response.data.data.length === 0 ||
        !response.data.data.some((item: { title: string }) =>
          item.title.toLowerCase().includes(title.toLowerCase())
        )
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No results found for the search term!",
        });
      } else {
        setMyData(response.data.data);
      }
      setLoad(false);
    } catch (error) {
      setIsError("Error fetching data. Please try again later.");
      setLoad(false);
    }
  };

  useEffect(() => {
    pageApidata();
  }, []);

  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      setIsError("Placeholder is required.");
      return;
    }
    searchBlogByTitle(searchTerm);
  };

  return (
    <>
      <RedirectUrlAfterLogin />
      {load && <Loader />}
      <Container fluid>
        <Row className="position-relative">
          <Col className="p-0">
            <img
              src={bgpage}
              alt="Blog Header"
              className="img-fluid"
              style={{ width: "100%" }}
            />
          </Col>
          <Col className="text-overlay">
            <Container>
              <h1 className="text-center font-weight-bold">
                Explore the hippest travel community's take on upcoming trends!
              </h1>
              <div className="row mt-2">
                <div className="col-md-5 col-8 mx-auto">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <span className="input-group-text" id="basic-addon2">
                      <FaSearch
                        onClick={handleSearch}
                        className="for_handleserach"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>

        <Container>
          <div className="container">
            <div className="horizontal-h-line"></div>
            <div className="row row-cols-1 row-cols-md-3 g-4 allc">
              {myData.map((item: MyDataItemIF, index: number) => (
                // <Link key={item._id} to={`/blogdetails/${item._id}`}>
                <div
                  className=""
                  key={item._id}
                  onClick={() => {
                    const id = item._id;
                    const slugUrl = item?.title
                      ?.toLowerCase()
                      .replace(/\s+/g, "-")
                      .replace(/\//g, "-")
                      .replace(/\((.*?)\)/g, "$1"); 
                    navigate(`/blogdetails/${slugUrl}`, {
                      state: id,
                    });
                  }}
                >
                  <Card key={index} className="card border-0    ">
                    <Card.Img
                      src={`${process.env.REACT_APP_API_URL}/${item.blog_image[0]?.src}`}
                      className="card-img-blog card-imgblog"
                    />
                    <div className="position-absolute overlay-text position-relative">
                      <h6 className=" font-weight-bold  blog-page-img-text for_alltext1">
                        {item.title}
                      </h6>
                    </div>
                  </Card>
                </div>
                // </Link>
              ))}
            </div>

            <div className="horizontal-h-line"></div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default BlogPage;
