import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import styles from "./styles";
import { useEffect, useState } from "react";
import axios from "axios";

interface BasicDocumentProps {
  id: string;
}

const BasicDocument: React.FC<BasicDocumentProps> = ({ id }) => {
  const [load, setLoad] = useState<boolean>(true);
  const [data, setData] = useState<any>();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<any>("");

  const stripHtmlTags = (html: string): string => {
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const fetchData = async () => {
    try {
      setLoad(true);
      let idd = id || "65dc650e9826ff34a0ca626b";
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/get-tourBySlugURLWEB`,
        {
          id: idd,
        }
      );
      if (response?.data) {
        setData(response.data.getData);
        setBackgroundImageUrl(
          `${process.env.REACT_APP_API_URL}/${response?.data?.getData?.banner_image[0]?.src}`
        );
      }
      setLoad(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <Image
            style={styles.logo}
            source={require("../../assets/images/glabol-logo.png")}
          />
          <View style={styles.fall}>
            <Text>{data?.overview_title}</Text>
            <Text>{data?.location}</Text>
          </View>
          <Image style={styles.image} source={backgroundImageUrl} />
          {[1, 2, 3, 4, 5].map((day) => (
            <View key={day} style={styles.section}>
              <Text style={styles.day}>Day {day}</Text>
              <Text style={styles.A}>{stripHtmlTags(data?.overview)}</Text>
              <View style={styles.container}>
                <Image
                  style={styles.imagee}
                  source={require("../../assets/images/first.png")}
                />
                <Image
                  style={styles.imagee}
                  source={require("../../assets/images/second.png")}
                />
                <Image
                  style={styles.imagee}
                  source={require("../../assets/images/third.png")}
                />
              </View>
            </View>
          ))}
          <View style={styles.notesdiv}>
            <Text style={styles.notes}>
              NOTE: We can swap the Pahalgam stay with Gulmarg and vice versa
              based on weather conditions and hotel availability. This way,
              there will be no change in the sightseeing options, as the number
              of nights at each destination will remain the same.
            </Text>
            <Text style={styles.notes}>
              NOTE: The age limit of our group departures is 16 to 42 years due
              to the power packed itineraries that we provide to our travellers.
              We can customise trips for travellers beyond the mentioned age
              bracket.
            </Text>
            <Text style={styles.notes}>
              NOTE: The age limit of our group departures is 16 to 42 years due
              to the power packed itineraries that we provide to our travellers.
              We can customise trips for travellers beyond the mentioned age
              bracket.
            </Text>
            <Text style={styles.notes}>
              NOTE: Kindly schedule flights arriving in Srinagar before 11:30 AM
              as our local sightseeing tour commences at 01:00 PM.
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default BasicDocument;
