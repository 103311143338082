import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../customer profile/CustomerProfile.css";
import Form from "react-bootstrap/Form";
import { RiEditLine } from "react-icons/ri";
import Swal from "sweetalert2";
import axios from "axios";
import { Loader } from "../loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
interface StateMappings {
  [abbreviation: string]: string;
}
const CustomerProfile = (props: any) => {
  const [editMode, setEditMode] = useState(false);
  const [load, setLoad] = useState<boolean>(true);
  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

   const stateMappings: StateMappings = {
     AP: "Andhra Pradesh",
     AR: "Arunachal Pradesh",
     AS: "Assam",
     BR: "Bihar",
     CG: "Chhattisgarh",
     GA: "Goa",
     GJ: "Gujarat",
     HR: "Haryana",
     HP: "Himachal Pradesh",
     JK: "Jammu and Kashmir",
     JH: "Jharkhand",
     KA: "Karnataka",
     KL: "Kerala",
     MP: "Madhya Pradesh",
     MH: "Maharashtra",
     MN: "Manipur",
     ML: "Meghalaya",
     MZ: "Mizoram",
     NL: "Nagaland",
     OD: "Odisha",
     PB: "Punjab",
     RJ: "Rajasthan",
     SK: "Sikkim",
     TN: "Tamil Nadu",
     TS: "Telangana",
     TR: "Tripura",
     UK: "Uttarakhand",
     UP: "Uttar Pradesh",
     WB: "West Bengal",
     AN: "Andaman and Nicobar Islands",
     CH: "Chandigarh",
     DN: "Dadra and Nagar Haveli",
     DD: "Daman and Diu",
     DL: "Delhi",
     LD: "Lakshadweep",
     PY: "Puducherry",
   };
 const stateMappings_2: StateMappings = {
   AP: "Andhra Pradesh",
   AR: "Arunachal Pradesh",
   AS: "Assam",
   BR: "Bihar",
   CG: "Chhattisgarh",
   GA: "Goa",
   GJ: "Gujarat",
   HR: "Haryana",
   HP: "Himachal Pradesh",
   JK: "Jammu and Kashmir",
   JH: "Jharkhand",
   KA: "Karnataka",
   KL: "Kerala",
   MP: "Madhya Pradesh",
   MH: "Maharashtra",
   MN: "Manipur",
   ML: "Meghalaya",
   MZ: "Mizoram",
   NL: "Nagaland",
   OD: "Odisha",
   PB: "Punjab",
   RJ: "Rajasthan",
   SK: "Sikkim",
   TN: "Tamil Nadu",
   TS: "Telangana",
   TR: "Tripura",
   UK: "Uttarakhand",
   UP: "Uttar Pradesh",
   WB: "West Bengal",
   AN: "Andaman and Nicobar Islands",
   CH: "Chandigarh",
   DN: "Dadra and Nagar Haveli",
   DD: "Daman and Diu",
   DL: "Delhi",
   LD: "Lakshadweep",
   PY: "Puducherry",
 };
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    state: "",
    mobile: "",
    address: "",
    gender: "",
    DOB: "",
    city: "",
  });

  let uData: any = localStorage.getItem("userData");
  let id = JSON.parse(uData)?._id;

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    setLoad(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/get-website-userById`,
        { id: id }
      );
      if (response.data.success) {
        const userData = response.data.getData;
         if (userData.state && stateMappings[userData.state]) {
           userData.state = stateMappings[userData.state];
         }
        props.change(userData);
        localStorage.setItem("userData", JSON.stringify(userData));
        setUserData(userData);
      } else {
        console.log("API giving false data in customer profile ");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
    setLoad(false);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = async (e: any) => {
    e.preventDefault();
    setEditMode(false);
    await saveFormData();
    fetchUserData();
  };

  const saveFormData = async () => {
    const reversedStateMappings = Object.fromEntries(
      Object.entries(stateMappings_2).map(([key, value]) => [value, key])
    );

    // Matching userData.state with the reversed mapping
    if (userData.state && reversedStateMappings[userData.state]) {
      userData.state = reversedStateMappings[userData.state];
    }
    const formData = {
      firstname: userData.firstname,
      lastname: userData.lastname,
      email: userData.email,
      state: userData.state,
      mobile: userData.mobile,
      address: userData.address,
      gender: userData.gender,
      DOB: userData.DOB,
      city: userData.city,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/put-user-profile?id=${id}`,
        formData
      );
      if (response.data.success) {
        setUserData(response.data.updatedUserData);
        Swal.fire({
          icon: "success",
          title: "Profile updated successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to save profile",
          text: response.data.message,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      const errorMessage = (error as Error).message;
      Swal.fire({
        icon: "error",
        title: "Error saving profile",
        text: errorMessage,
        showConfirmButton: true,
      });
    }
  };

  return (
    <>
      {load && <Loader />}
      <div>
        <Form.Group className="mb-3 customer-border lable pt-4">
          <span className="profile">Profile</span>
          {editMode ? (
            <span className="save" onClick={handleSaveClick}>
              <RiEditLine />
              Save
            </span>
          ) : (
            <span className="edit" onClick={handleEditClick}>
              <RiEditLine /> Edit
            </span>
          )}
        </Form.Group>

        {userData && (
          <form className="row g-3 mb-4 detail">
            <div className="col-md-6 col-lg-4 lable">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className={`form-control place ${
                  editMode ? "edit-mode" : "non_editeable"
                }`}
                id="firstname"
                placeholder="Enter First Name"
                value={userData.firstname}
                disabled={!editMode}
                onChange={(e) =>
                  setUserData({ ...userData, firstname: e.target.value })
                }
              />
            </div>
            <div className="col-md-6 col-lg-4 lable">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                className={`form-control place ${
                  editMode ? "edit-mode" : "non_editeable"
                }`}
                id="lastname"
                placeholder="Enter Last Name"
                value={userData.lastname}
                disabled={!editMode}
                onChange={(e) =>
                  setUserData({ ...userData, lastname: e.target.value })
                }
              />
            </div>
            <div className="col-md-6 col-lg-4 lable">
              <label className="form-label">Contact Number</label>
              <input
                type="tel"
                className={`form-control place ${
                  editMode ? "edit-mode" : "non_editeable"
                }`}
                id="mobile"
                placeholder="ex. 9845455466"
                value={userData.mobile}
                disabled={true}
                onChange={(e) =>
                  setUserData({ ...userData, mobile: e.target.value })
                }
              />
            </div>
            <div className="col-md-6 lable">
              <label className="form-label">E-mail ID</label>
              <input
                type="text"
                className={`form-control place ${
                  editMode ? "edit-mode" : "non_editeable"
                }`}
                id="email"
                placeholder="ex. john@gmail.com"
                value={userData.email}
                disabled={true}
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              />
            </div>
            <div className="col-md-6 lable">
              <label className="form-label">Address</label>
              <input
                type="text"
                className={`form-control place ${
                  editMode ? "edit-mode" : "non_editeable"
                }`}
                id="address"
                placeholder="sangam nagar"
                value={userData.address}
                disabled={!editMode}
                onChange={(e) =>
                  setUserData({ ...userData, address: e.target.value })
                }
              />
            </div>

            <div className="col-md-6 lable">
              <label className="form-label">Select Gender</label>
              <div className="select-wrapper">
                <select
                  id="gender"
                  className={`form-select place ${
                    editMode ? "edit-mode" : "non_editeable"
                  }`}
                  aria-placeholder="select Gender"
                  value={userData.gender}
                  disabled={!editMode}
                  onChange={(e) =>
                    setUserData({ ...userData, gender: e.target.value })
                  }
                >
                  <option value="" disabled>
                    Select Gender
                  </option>
                  {["Male", "Female"].map((gender, index) => (
                    <option key={index} value={gender}>
                      {gender}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon" />
              </div>
            </div>

            <div className="col-md-6 lable">
              <label className="form-label">Date of Birth</label>
              <input
                type="date"
                className={`form-control place ${
                  editMode ? "edit-mode" : "non_editeable"
                }`}
                id="DOB"
                value={userData.DOB}
                disabled={!editMode}
                onChange={(e) =>
                  setUserData({ ...userData, DOB: e.target.value })
                }
              />
            </div>
            <div className="col-md-6 lable">
              <label className="form-label">City</label>
              <input
                type="text"
                className={`form-control place ${
                  editMode ? "edit-mode" : "non_editeable"
                }`}
                id="city"
                placeholder="ex. Indore"
                value={userData.city}
                disabled={!editMode}
                onChange={(e) =>
                  setUserData({ ...userData, city: e.target.value })
                }
              />
            </div>
            <div className="col-md-6 lable">
              <label className="form-label">State</label>
              <div className="select-wrapper">
                <select
                  id="state"
                  className={`form-select place ${
                    editMode ? "edit-mode" : "non_editeable"
                  }`}
                  aria-placeholder="Select State"
                  value={userData.state}
                  disabled={!editMode}
                  onChange={(e) =>
                    setUserData({ ...userData, state: e.target.value })
                  }
                >
                  <option value="" >
                    Select State
                  </option>
                  {states.map((ele, index) => (
                    <option key={index} value={ele}>
                      {ele}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon" />
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default CustomerProfile;
