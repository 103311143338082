import React, { useState, useEffect } from "react";
import axios from "axios";
import "./personalized.css";
import { Loader } from "../loader/Loader";
import { useNavigate } from "react-router-dom";

interface IData {
  title: string;
  tour_image: { src: string }[];
  domesticorInternational: string;
}

function Personalized() {
  const [activeTab, setActiveTab] = useState<"international" | "domestic">(
    "domestic"
  );
  const [data, setData] = useState<IData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [load, setLoad] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoad(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-alltourpackageCustomized`
      );
      setData(response?.data?.data);
    } catch (error) {
      setError("Error fetching data. Please try again later.");
    } finally {
      setLoad(false);
    }
  };
  const filteredData = Array.isArray(data)
    ? data.filter((item) => {
        return activeTab === "international"
          ? item.domesticorInternational === "international"
          : item.domesticorInternational === "domestic";
      })
    : [];

  return (
    <>
      {load ? <Loader /> : ""}
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-12 col-md-6">
              <ul
                className="nav nav-tabs nav-fill customnav"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "domestic" ? "active" : ""
                    } for_btntab`}
                    onClick={() => setActiveTab("domestic")}
                  >
                    DOMESTIC
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "international" ? "active" : ""
                    } for_btntab`}
                    onClick={() => setActiveTab("international")}
                  >
                    INTERNATIONAL
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="container mb-5">
                    <div className="row row-cols-1 row-cols-md-4 g-4 mt-3 mobile-personalized">
                      {filteredData.map((item: any, index) => (
                        <div className="col col-6" key={index}>
                          {/* <Link
                            to={`/tripdetails/${item._id}`}
                            className="featuredlink"
                          > */}
                            <div className="card border-0 card-img-top-personalized"
                             onClick={() => {
                      const id = item._id;
                      const slugUrl = item?.title

                        ?.toLowerCase()
                        .replace(/\s+/g, "-")
                        .replace(/\//g, "-")
                        .replace(/\((.*?)\)/g, "$1"); 
                      navigate(`/tripdetails/${slugUrl}`, {
                        state: id,
                      });
                    }}>
                              <div className="for_personalized">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/${item?.tour_image[0]?.src}`}
                                  className="card-img-top-personalized for_personalized"
                                  alt={item.title}
                                />
                                <div
                                  className="for_personalized_under"
                                  style={{
                                    position: "absolute",
                                    top: "0%",
                                    left: "0%",
                                    width: "80%",
                                  }}
                                >
                                  <span
                                    className="p-16 w-full for_underdrop"
                                    style={{
                                      background:
                                        index % 2 === 0
                                          ? "rgba(255, 169, 35, 1)"
                                          : "#DC1B5E",
                                      clipPath:
                                        "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)",
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    {item.title}
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "1rem",
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          {/* </Link> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Personalized;
